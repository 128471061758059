import React, { useState } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Carousel, Modal, PageSection, PageTextRectangle, PageBanner } from '../components'
import { StaticImage } from 'gatsby-plugin-image'
// import AliceCarousel from 'react-alice-carousel';

// import 'react-alice-carousel/lib/scss/alice-carousel.scss';

import image1 from '../assets/images/galerija_1.jpg'
import image2 from '../assets/images/galerija_2.jpg'
import image3 from '../assets/images/galerija_3.jpg'
import image4 from '../assets/images/galerija_4.jpg'
// import Menu from '../components/Menu'

// const slideImages = [
//   image1,
//   image2,
//   image3,
//   image4,
// ];

// const createItems = (length, [handleClick]) => {
//   let deltaX = 0;
//   let difference = 0;
//   const swipeDelta = 20;
//
//   return Array.from({ length }).map((item, i) => (
//     <div
//       data-value={i + 1}
//       className="item"
//       onMouseDown={(e) => (deltaX = e.pageX)}
//       onMouseUp={(e) => (difference = Math.abs(e.pageX - deltaX))}
//       onClick={() => (difference < swipeDelta) && handleClick(i)}
//     >
//       <span className='item-inner'>
//         <img
//           className='page-banner-image'
//           src={slideImages[i]}
//           alt='galerija'
//           // layout='fullWidth'
//           // onDragStart={handleDragStart}
//         />
//       </span>
//       {/*<span className="item-inner" />*/}
//     </div>
//   ));
// };

const Galerija = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  
  // const [activeIndex, setActiveIndex] = useState(0);
  // const [items] = useState(createItems(slideImages.length, [setActiveIndex]));
  //
  // const slidePrev = () => setActiveIndex(activeIndex - 1);
  // const slideNext = () => setActiveIndex(activeIndex + 1);
  // const syncActiveIndex = ({ item }) => setActiveIndex(item);
  
  // const [ isMenuVisible, setIsMenuVisible ] = useState(false)
  
  // const handleDragStart = (e) => e.preventDefault();
  
  const handleToggleModal = () => {
    setIsModalOpen(prevState => !prevState)
  }
  
  // const items = slideImages.map(image => (
  //   <StaticImage
  //     className='page-banner-image'
  //     src={image}
  //     alt='galerija'
  //     layout='fullWidth'
  //     onDragStart={handleDragStart}
  //   />
  // ))
  // TODO: this is items
  // const items = slideImages.map(image => (
  //   <img
  //     className='page-banner-image'
  //     src={image}
  //     alt='galerija'
  //     // layout='fullWidth'
  //     onDragStart={handleDragStart}
  //   />
  // ))
  // const items = [
  //   <img src='../assets/images/galerija_1.jpg' onDragStart={handleDragStart} />,
  //   <img src='../assets/images/galerija_2.jpg' onDragStart={handleDragStart} />,
  //   <img src='../assets/images/galerija_3.jpg' onDragStart={handleDragStart} />,
  //   <img src='../assets/images/galerija_4.jpg' onDragStart={handleDragStart} />,
  // ];
  return (
    <Layout>
      <Helmet>
        <title>Krasto pazinimas - galerija</title>
        <meta name='description' content='galerija' />
      </Helmet>
      
      <PageBanner title='Galerija'>
        <StaticImage
          className='page-banner-image'
           // todo: change to actual
          src='../assets/images/projektai_banner.jpg'
          alt='galerija'
          layout='fullWidth'
        />
      </PageBanner>
      
      {/*{projektaiPage.map(({ paragraph, title}, index) => (*/}
      {/*  <PageSection key={`${index}-${title}`} className="section-4 content-page">*/}
      {/*    <PageTextRectangle*/}
      {/*      className='white-text-background'*/}
      {/*      title={title}*/}
      {/*      text={paragraph}*/}
      {/*    />*/}
      {/*  </PageSection>*/}
      {/*))}*/}
      
      <PageSection className='section-4 content-page'>
        <div className='sponsors-logo'>
          <StaticImage
            // className="page-banner-image"
            src='../assets/images/ltk_logo.png'
            alt='ltk_logo'
            width={300}
            // layout="fullWidth"
            onClick={handleToggleModal}
          />
          
          <StaticImage
            // className="page-banner-image"
            src='../assets/images/simono_daukanto_gimnazija_logo.jpg'
            alt='simono_daukanto_gimnazija_logo'
            width={100}
            // layout="fullWidth"
            onClick={handleToggleModal}
          />
          
          <StaticImage
            // className="page-banner-image"
            src='../assets/images/akmenes_rajono_savivaldybes_logo.png'
            alt='akmenes_rajono_savivaldybes_logo'
            width={300}
            onClick={handleToggleModal}
            // layout="fullWidth"
          />
        </div>
      </PageSection>
  
      {/*<PageSection onClick={handleToggleMenu}>*/}
      {/*  /!*<div onClick={handleToggleMenu}>*!/*/}
      {/*  <AliceCarousel mouseTracking items={items} />*/}
      {/*  /!*</div>*!/*/}
      {/*</PageSection>*/}
      
      <Modal isOpen={isModalOpen} handleToggleModal={handleToggleModal}>
        <Carousel />
        {/*<AliceCarousel*/}
        {/*  mouseTracking*/}
        {/*  disableDotsControls*/}
        {/*  disableButtonsControls*/}
        {/*  items={items}*/}
        {/*  activeIndex={activeIndex}*/}
        {/*  // responsive={responsive}*/}
        {/*  onSlideChanged={syncActiveIndex}*/}
        {/*/>*/}
        {/*<div className="b-refs-buttons carousel-button-wrapper">*/}
        {/*  <button onClick={slidePrev}>Prev</button>*/}
        {/*  <button onClick={slideNext}>Next</button>*/}
        {/*</div>*/}
      </Modal>
    </Layout>
  )
}

export default Galerija

// todo:
//  1. image
//   a) size
//   b) centering
//   c) reset state after modal close
//   d) open image that was clicked
//  2. modal background
//   a) size,
//   b) transparency,
//   c) color
//   d) hide numbering
//  3. image loading via graphql - all app
